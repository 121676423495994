import React from 'react';
import { useLocation } from "react-router";
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SecureRoute } from '@okta/okta-react';
import { find, isEmpty } from 'lodash';
import { getUserInfo, isOKTAStorageAvailable, setRedirectUrl } from 'helpers/utilCommon';

export const PrivateRoutes = ({ component: Component,  ...rest }) => {
    const { isReactTesting } = rest;
    const Router = isReactTesting ? Route : SecureRoute
    const { pathname, search } = useLocation();
    const userInfo = useSelector(store => store.user.userInfo);
    // To redirect to same url after login
    if (pathname !== '/MyASTM' && !rest.isSignOutTrigger && (rest.isMemberStillLoginInAnotherTab !== "false")) {
        const path = pathname + search;
        setRedirectUrl(path);
    }
    return (
        <Router {...rest} render={props => (
            !isEmpty(userInfo) && Component ? <Component {...props}  /> : null
        )} />
    );
}

export const PublicRoute = ({ component: Component, isRedirectOnPublicPage, ...rest }) => {
    const userInfo = useSelector(store => store.user.userInfo);

    const { path } = rest;
    if (path === '/application' && userInfo && userInfo.isMember) {
        return <Redirect to={'/MyASTM/MemberAccess'} />
    }

    return (
        <Route {...rest} render={props => (
            <Component {...props} />
        )} />
    );
};

export const EntitlementProtectedRoute = ({ component: Component, appEntitlementList, isEntitlementLoad, checkTitle, isComingFromExternalApp, isHistoricalMember, ...rest }) => {
    if (isEntitlementLoad) {
        return null;
    }

    const { path } = rest;

    let isAllow = false;

    if (!isHistoricalMember) {
        if (checkTitle) {
            if (typeof checkTitle === "string") {
                isAllow = find(appEntitlementList, { title: checkTitle });
            } else {
                isAllow = find(appEntitlementList, (list) => {
                    return checkTitle.indexOf(list.title) !== -1;
                });
            }
        } else {
            isAllow = find(appEntitlementList, { path });
        }
    }

    return (
        <Route
            {...rest}
            render={(props) => (isAllow ?
                <Component {...props} /> : <Redirect to={{ pathname: '/MyASTM', state: { isComingFromExternalApp } }} />
            )}
        />
    );
};


// TODO : Check Current routes is public Routes.
export const isPublicRoutes = () => {
    let publicUrls = ['application', 'Msgs', 'checkout', 'home', 'download'];
    let addressPath = window.location.pathname ? window.location.pathname.toLowerCase() : '';
    let index = 1; // Default index After slash.
    if (addressPath) {
        let Urls = addressPath.split('/')[index];
        if (publicUrls.indexOf(Urls) > -1) {
            return true;
        }
    }
    return false;
}


export default {
    PrivateRoutes,
    EntitlementProtectedRoute,
    PublicRoute,
    isPublicRoutes
}