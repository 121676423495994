import * as type from './types';

const initialState = {
  standardsTrackingDetails: {},
  isMainDataLoading: false,
  standardTrackingApiStatus: false
};

const reducerStandardsTracking = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_IS_LOADING_DATA:
      return {
        ...state,
        isMainDataLoading: action.payload
      }
    case type.SET_ALL_STANDARDS_TRACKING_DATA:
      return {
        ...state,
        standardsTrackingDetails: action.payload
      };

    case type.CLEAR_STANDARDS_TRACKING_DATA:
      return {
        ...state,
        standardsTrackingDetails: {}
      }
    
    case type.CLEAR_STANDARDS_TRACKING_API_STATUS:
      return {
        ...state,
        standardTrackingApiStatus: action.status
      }

    default:
      return state;
  }
};

export default reducerStandardsTracking;
