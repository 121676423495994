import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router";
import { signOut } from "redux/login/actions";
import { StaticPages } from 'models/navigation.models';

const IdentityDelegationBanner = () => {
    const userInfo = useSelector(store => store.user.userInfo);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    if (!userInfo?.proxy_user || pathname.toLowerCase() === StaticPages.StandardsTracking.toLowerCase()) {
        return null;
    }

    const { memberAccountNumber } = userInfo;
    const { firstName, lastName } = userInfo.proxy_user;

    const handleSignOut = () => {
        dispatch(signOut(dispatch, true))
    }

    return (
        <div>
            <div className="card astm-card astm-card--flat alert-danger">
                <div className="container">
                    <div className={"card-text d-flex flex-row justify-content-between pt-2 pb-2"}>
                        <span className={"astm-type-body--lead align-self-center renewal-reinstate-widget-descriptive-text mb0"}>
                            <i className="astm-icon astm-icon astm-icon--light-danger fal fa-exclamation-square"></i>&nbsp;
                            Identity Delegation Active for - <b>{`${firstName} ${lastName} (${memberAccountNumber})`}</b></span>
                        <button className={"btn astm-btn btn-outline-danger align-self-center"} onClick={handleSignOut}>Exit Delegation</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IdentityDelegationBanner;