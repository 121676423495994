import types from './types';

const INITIAL_STATE = {
  selectedCommittee: null,
  selectedSubCommittee: null,
  selectedMember: null
}

const committeeRosterReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.SET_SELECTED_COMMITTEE: {
      return {
        ...state,
        selectedCommittee: action.value
      }
    }

    case types.SET_SELECTED_SUB_COMMITTEE: {
      return {
        ...state,
        selectedSubCommittee: action.value
      }
    }

    case types.SET_SELECTED_MEMBER: {
      return {
        ...state,
        selectedMember: action.value
      }
    }

    default: return state;
  }
}

export default committeeRosterReducer;