import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { setAppLoader } from '../redux/app/actions';
import { clearRedis } from "../services/appServices";
import { clearSession, getUserInfo } from './utilCommon';
import { removeAppCookies } from "./manageCookies";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export let PROTACTED_HOME_PAGE = '/MyASTM';

// Setup Configuration for OktaAuth. 
export const authClient = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true, // [true] provides a modern solution for protecting SPAs. 
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URI,
    disableHttpsCheck: window.location.hostname !== 'localhost' ? true : false, // [True] if working on https environment.
    tokenManager: {
        expireEarlySeconds: 60,
        autoRenew: true, // autorenew [true] auto update access token
        secure: window.location.hostname !== 'localhost' ? true : false, // This option is only relevant if storage is set to cookie and false option is only for testing
    },
    services: {
        autoRenew: true,
        autoRemove: true,
    }
});

export const handleLoggedIn = async (redirectURL = '') => {
    let redirectCallbackURL = '';
    if (redirectURL) {
        redirectCallbackURL += `?callbackUrl=${redirectURL}`
    }
    const genratedRedirectURL = process.env.REACT_APP_LOGIN_PAGE_REQUEST + window.btoa(window.location.origin + redirectCallbackURL);
    window.location.assign(genratedRedirectURL);
}

let isHandleLogoutRedirectCalled = false;
export const handleLoggedOut = async (dispatch, redirectURL = '') => {
    if (isHandleLogoutRedirectCalled) {
        return;
    }
    dispatch(setAppLoader(true));
    clearRedis(async () => {
        isHandleLogoutRedirectCalled = true;
        clearSession();
        removeAppCookies(false);
        window.localStorage.setItem('isMemberStillLoginInAnotherTab', false)
        await authClient.signOut();
        handleLoggedIn(redirectURL);
    });
}

export const redirectUrlHandler = () => {
    const redirectUrl = sessionStorage.getItem("REDIRECT_URL");
    return redirectUrl || null;
}

export const OktaSecurityHOC = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const hashValue = window.location.hash;

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const isRedirectFromRM = urlParams.get('rmLogout');
        if (isRedirectFromRM) {
            (async () => {
                const isSessionExist = await authClient.session.exists();
                if (!isSessionExist) {
                    handleLoggedOut(dispatch, '/RMLogin');
                } else {
                    window.location.replace(window.location.origin + '/RMLogin');
                }
            })();
        }
        // https://github.com/okta/okta-auth-js/issues/1365#issuecomment-1387698694
        (async () => {
            await authClient.start();
        })();

        return () => {
            (async () => {
                await authClient.stop();
            })();
        }
    }, []);

    React.useEffect(() => {
        if (hashValue) {
            let sessionToken = window.location.hash.split('#')[1];
            if (sessionToken === 'IdentityDelegationRefreshToken') {
                clearSession();
                window.location.replace(window.location.origin);
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const redirectUrlParams = urlParams.get('callbackUrl');
                window.sessionStorage.setItem("REDIRECT_CALLBACK_URL", window.location.pathname?.length ? redirectUrlParams : PROTACTED_HOME_PAGE);
                authClient.token.getWithRedirect({
                    scopes: authClient.options.scopes,
                    sessionToken
                });
            }
        }
    }, [hashValue]);

    if (hashValue) {
        return null;
    }

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        const redirectUrl = window.sessionStorage.getItem("REDIRECT_CALLBACK_URL");
        history.replace(toRelativeUrl(redirectUrl || originalUri || '/', window.location.origin));
        window.sessionStorage.removeItem("REDIRECT_CALLBACK_URL");
    };

    const handleAuth = async () => {
        if (!hashValue) {
            const isSessionExist = await authClient.session.exists();
            if (!isSessionExist) {
                const callbackUrl = redirectUrlHandler();
                if (callbackUrl) {
                   handleLoggedIn(callbackUrl);
                } else {
                   handleLoggedIn(window.location.pathname + window.location.search);
                }
            } else {
                setTimeout(() => {
                    const idToken = authClient.getIdToken();
                    if (!idToken) {
                        authClient.token.getWithRedirect({
                            scopes: authClient.options.scopes
                        });
                    }
                }, 1000);
            }
        }
    };

    return (
        <Security 
            oktaAuth={authClient}
            onAuthRequired={handleAuth}
            restoreOriginalUri={restoreOriginalUri}
        >
            <Route path="/auth" component={LoginCallback} />
            { props.children }
        </Security>
    );
}

// TODO : get and renew auth token header.
export const getAuthHeaderToken = async () => {
    const tokenInfo = await authClient.tokenManager.get('accessToken'); 
    if (tokenInfo) {
        // To check token is valid.
        if (authClient.tokenManager.hasExpired(tokenInfo)) {
            const renewAccessToken = await authClient.token.renew(tokenInfo); 
            return renewAccessToken ? renewAccessToken.accessToken : null;
        } else {
            return tokenInfo.accessToken;
        }
    } else {
        return null;
    }
};

export const validateToken = (callback) => {
    const idToken = authClient.getIdToken();
    if (!idToken) {
        authClient.token.getWithRedirect({
            scopes: authClient.options.scopes
        });
    } else {
        callback();
    }
}