import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { getCookie, setCookie } from 'helpers/manageCookies';
import { cookieKeysEnum } from 'models/common.models';
import { bannerFeatureAction } from '../../services/appServices';

const AlertBanner = () => {
    const [bannerInfo, setBannerInfo] = React.useState({});

    React.useEffect(() => {
        if (getCookie(cookieKeysEnum["BANNER-DISPLAY"])) {
            setBannerInfo(JSON.parse(getCookie(cookieKeysEnum["BANNER-DISPLAY"])));
        }
    }, []);

    React.useEffect(() => {
        if (!getCookie(cookieKeysEnum["BANNER-DISPLAY"])) {
            loadBannerInfo();
        }
    });

    const loadBannerInfo = () => {
        bannerFeatureAction(bannerResponse => {
            if (bannerResponse) {
                if (bannerResponse.alerttype === 'information') {
                    bannerResponse.alerttype = 'info';
                }
                bannerResponse.isShowAlertBanner = bannerResponse.IsDisplayAlert;
                setCookie(cookieKeysEnum["BANNER-DISPLAY"], JSON.stringify(bannerResponse), process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
                setBannerInfo(bannerResponse);
            }
        });
    }

    const onCloseBanner = () => {
        const updatedBannerInfo = { ...bannerInfo, isShowAlertBanner: false };
        setCookie(cookieKeysEnum["BANNER-DISPLAY"], JSON.stringify(updatedBannerInfo));
        setBannerInfo(updatedBannerInfo);
    };

    if (!bannerInfo.isShowAlertBanner) {
        return null
    }

    return (
        <Alert onClose={onCloseBanner} variant={bannerInfo.alerttype} className={'astm-alert banner-alert bg-' + bannerInfo.alerttype + '--light'} closeLabel={''} dismissible>
            <div className='flex-box' data-testid = 'alert-message'>
                <span className="astm-alert-text">{bannerInfo.alertmessage}</span>
            </div>
        </Alert>
    );
}

export default AlertBanner;