import 'polyfill-array-includes';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';
import { generateUUID } from './src/helpers/utilCommon';
import { FlagFeaturesProvider } from './src/providers/flagFeature';
import * as serviceWorker from './src/serviceWorker';
import store from './src/store';
import App from 'components/pages/app';
import ErrorBoundary from 'components/organisms/ErrorBoundary';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'astm-ux-design-system/dist/bundle.css';
import './src/assets/themes/main.css';
import './src/index.css';

(() => {
  let isJiraEnabled = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_ENABLED;
  if (isJiraEnabled === 'true') {
    let src = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL;
    document.write('<script src="' + src + '"><\/script>');
  }
})();

const uniqueId = generateUUID();

datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'debug', 'info'],
    storeContextsAcrossPages: true,
    allowUntrustedEvents: true,
    sendLogsAfterSessionExpiration: true,
    beforeSend: (event) => {
        if (event.message.indexOf('Warning:') === 0) {
            return false;
        } else {
            event.message = `${uniqueId}:: ${event.message}`
        }
    }
});

createRoot(document.getElementById('root')).render(
    <ErrorBoundary>
        <Provider store={store}>
            <FlagFeaturesProvider>
                <Router basename='/'>
                    <App />
                </Router>
            </FlagFeaturesProvider>
        </Provider>
    </ErrorBoundary>
);

serviceWorker.unregister();