// It is used for code splitting and provides lazy loading
import React, { Component } from 'react';
import { connect } from "react-redux";
import { setMessage } from './redux/shared/actions';
const asyncComponent = (importComponent) => {
	return class extends Component {
		state = {
			component: null,
			isLoadNetworkIssueComponent: false 
		}

		componentDidMount() {
			importComponent()
				.then(cmp => {
					this.setState({ component: cmp.default, isLoadNetworkIssueComponent: false });
				}).catch(err => {
					this.setState({ isLoadNetworkIssueComponent: true });
				});
		}

		render() {
			const { component: C, isLoadNetworkIssueComponent } = this.state;
			if(isLoadNetworkIssueComponent) {
				return <NetworkIssueContainer />
			}
			return C ? <C {...this.props} /> : null;
		}
	}
};
export default asyncComponent;
class NetworkIssueComponent extends Component {
	componentDidMount(){
		this.props.setMessage('4003');
	}
	render(){
		return null
	}
}
const mapDispatchToProps = dispatch => ({
    setMessage: (code) => dispatch(setMessage(true, code))
});

const NetworkIssueContainer = connect(
    null,
    mapDispatchToProps
)(NetworkIssueComponent);
