import types from './types';

const INITIAL_STATE = {
    subcommitteeListJoinDrop: [],
    droppedMainCommittees: [],
    droppedMainCommitteesAdditionalData: [],
    reinstateMainCommittee: [],
    reinstateVolumeObj: {},
    reinstateForm: null,
    futureMembershipType: {
      isLoading: true,
      info: null
    }
}

const membershipReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.SET_SUBCOMMITTEE_LIST_JOIN_DROP: {
      return {
        ...state,
        subcommitteeListJoinDrop: action.payload
      }
    }
    case types.SET_DROPPED_MAIN_COMMITTEES_DATA: {
      return {
        ...state,
        droppedMainCommittees: action.payload
      }
    }
    case types.SET_DROPPED_MAIN_COMMITTEES_ADDITIONAL_DATA: {
      return {
        ...state,
        droppedMainCommitteesAdditionalData: action.payload
      }
    }
    case types.SET_FUTURE_MEMBERSHIP_TYPE: {
      return {
        ...state,
        futureMembershipType: action.payload
      }
    }
    case types.RESET_FUTURE_MEMBERSHIP_TYPE: {
      return {
        ...state,
        futureMembershipType: { isLoading: true, info: null }
      }
    }
    case types.SET_REINSTATE_MAIN_COMMITTEES: {
      return {
        ...state,
        reinstateMainCommittee: action.payload
      }
    }
    case types.SET_REINSTATE_VOLUME: {
      return {
        ...state,
        reinstateVolumeObj: action.payload
      }
    }
    case types.RESET_REINSTATE_MEMBERSHIP_DATA: {
      return {
        ...state,
        subcommitteeListJoinDrop: [],
        droppedMainCommittees: [],
        droppedMainCommitteesAdditionalData: [],
        reinstateMainCommittee: [],
        reinstateVolumeObj: {}
      }
    }
    case types.SET_REINSTATE_FROM: {
      return {
        ...state,
        reinstateForm: action.payload,
        subcommitteeListJoinDrop: [],
        droppedMainCommittees: [],
        droppedMainCommitteesAdditionalData: [],
        reinstateMainCommittee: [],
        reinstateVolumeObj: {},
        futureMembershipType: {
          isLoading: true,
          info: null
        }
      }
    }
    default: return state;
  }
}

export default membershipReducer;