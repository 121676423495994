import types from './types';

const INITIAL_STATE = {
  aboutYourselfOrOrganization: {
    isNotAffiliated: false
  },
  // member info form
  memberStateMasterList: [],
  isShowMemberStateDropdown: false,
  // company info form
  companyStateMasterList: [],
  isShowCompanyStateDropdown: false,
  // change of emp
  coe_committeeArray: [],
  coe_droppedMainCommittee: [],
  coe_droppedSubCommittee: []
}

const membershipApplication = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.SET_ABOUT_YOURSELF_OR_ORGANIZATION: {
      return {
        ...state,
        aboutYourselfOrOrganization: action.payload
      }
    }
    case types.RESET_ABOUT_YOURSELF_OR_ORGANIZATION: {
      return {
        ...state,
        aboutYourselfOrOrganization: {
          isNotAffiliated: false
        }
      }
    }
    case types.SET_MEMBER_STATE_MASTER_LIST: {
      return {
        ...state,
        memberStateMasterList: action.payload
      }
    }
    case types.SET_SHOW_MEMBER_STATE_DROPDOWN: {
      return {
        ...state,
        isShowMemberStateDropdown: action.payload
      }
    }
    case types.SET_COMPANY_STATE_MASTER_LIST: {
      return {
        ...state,
        companyStateMasterList: action.payload
      }
    }
    case types.SET_SHOW_COMPANY_STATE_DROPDOWN: {
      return {
        ...state,
        isShowCompanyStateDropdown: action.payload
      }
    }
    case types.RESET_COE_FORM: {
      return {
        ...state,
        coe_committeeArray: [],
        coe_droppedMainCommittee: [],
        coe_droppedSubCommittee: []
      }
    }
    case types.SET_COE_COMMITTEE_ARRAY: {
      return {
        ...state,
        coe_committeeArray: action.payload
      }
    }
    case types.SET_COE_DROP_MAIN_COMMITTEE: {
      return {
        ...state,
        coe_droppedMainCommittee: action.payload
      }
    }
    case types.SET_COE_DROP_SUB_COMMITTEE: {
      return {
        ...state,
        coe_droppedSubCommittee: action.payload
      }
    }
    case types.SET_EMAIL_PERMISSION: {
      const { payload } = action;
      return {
        ...state,
        emailPermission: payload
      };
    }

    case types.SET_STUDENT_PERMISSION: {
      const { payload } = action;
      return {
        ...state,
        studentPermission: payload
      };
    }
    default: return state;
  }
}

export default membershipApplication;