const SET_ABOUT_YOURSELF_OR_ORGANIZATION = 'SET_ABOUT_YOURSELF_OR_ORGANIZATION';
const RESET_ABOUT_YOURSELF_OR_ORGANIZATION = 'RESET_ABOUT_YOURSELF_OR_ORGANIZATION';
const SET_MEMBER_STATE_MASTER_LIST = 'SET_MEMBER_STATE_MASTER_LIST';
const SET_SHOW_MEMBER_STATE_DROPDOWN = 'SET_SHOW_MEMBER_STATE_DROPDOWN';
const SET_COMPANY_STATE_MASTER_LIST = 'SET_COMPANY_STATE_MASTER_LIST';
const SET_SHOW_COMPANY_STATE_DROPDOWN = 'SET_SHOW_COMPANY_STATE_DROPDOWN';
const RESET_COE_FORM = 'RESET_COE_FORM';
const SET_COE_COMMITTEE_ARRAY = 'SET_COE_COMMITTEE_ARRAY';
const SET_COE_DROP_MAIN_COMMITTEE = 'SET_COE_DROP_MAIN_COMMITTEE';
const SET_COE_DROP_SUB_COMMITTEE = 'SET_COE_DROP_SUB_COMMITTEE';
const SET_EMAIL_PERMISSION = 'SET_EMAIL_PERMISSION';
const SET_STUDENT_PERMISSION = 'SET_STUDENT_PERMISSION';

export default {
    SET_ABOUT_YOURSELF_OR_ORGANIZATION,
    RESET_ABOUT_YOURSELF_OR_ORGANIZATION,
    SET_MEMBER_STATE_MASTER_LIST,
    SET_SHOW_MEMBER_STATE_DROPDOWN,
    SET_COMPANY_STATE_MASTER_LIST,
    SET_SHOW_COMPANY_STATE_DROPDOWN,
    RESET_COE_FORM,
    SET_COE_COMMITTEE_ARRAY,
    SET_COE_DROP_MAIN_COMMITTEE,
    SET_COE_DROP_SUB_COMMITTEE,
    SET_EMAIL_PERMISSION,
    SET_STUDENT_PERMISSION
}