import React from 'react';
import { Spinner } from 'react-bootstrap';

const CustomLoader = ({testId}) => (
    <div data-testid={testId} active='true'>
        <div className="commonLoader">
            <div className="loaderWrap">
                <Spinner animation="border" />
            </div>
        </div>
    </div>
);

export default CustomLoader;
