import types from './types';
import loginTypes from '../login/types';

const INITIAL_STATE = {
  isLoading: true,
  entitlementList: [],
  appEntitlementList: [],
  menuList: null
};

const entitlementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ENTITLEMENT: {
      const { payload } = action;
      return {
        ...state,
        entitlementList: payload
      };
    }
    case types.SET_APP_ENTITLEMENT: {
      const { payload } = action;
      return {
        ...state,
        appEntitlementList: payload,
        isLoading: false
      };
    }
    case types.SET_MENU_MAPPED_LIST: {
      const { payload } = action;
      return {
        ...state,
        menuList: payload,
      };
    }
    case loginTypes.SIGN_OUT: {
      return {
        ...INITIAL_STATE
      };
    }
    default: return state;
  }
}

export default entitlementReducer;