import { StaticPages } from 'models/navigation.models';
import React from 'react';
import { withRouter } from 'react-router-dom';
import HeaderSearchBox from "./../atoms/SearchBox";

const Footer = (props) => {
    const { location } = props;

    if (location &&
        (location.pathname === "/Msgs/classification" ||
            location.pathname === "/Msgs/votingstatus" ||
            location.pathname === "/Msgs/nonvotereason" ||
            location.pathname === "/MyASTM/MembershipInfo/Card" ||
            location.pathname === "/MyASTM/MembershipInfo/Certificate" ||
            location.pathname === "/MyASTM/MembershipInfo/Membership-Renewal-Invoice" ||
            location.pathname === "/MyASTM/MembershipInfo/Membership-Receipt" ||
            location.pathname.toLowerCase() === StaticPages.StandardsTracking.toLowerCase() ||
            location.pathname.toLowerCase() === StaticPages.CitingASTMStandards.toLowerCase())
    ) {
        return null;
    }

    return (
        <footer data-testid="footer-component">
            <div className="container">
                <div className="linknsearchWrap">
                    <div className="socialLinks">
                        <ul>
                            <li><a href="https://twitter.com/ASTMIntl" target="_blank"><i className="fab fa-twitter-square"></i></a></li>
                            <li><a href="https://www.facebook.com/ASTMInternational/" target="_blank"><i className="fab fa-facebook-square"></i></a></li>
                            <li><a href="https://www.youtube.com/c/ASTMIntlSDO" target="_blank"><i className="fab fa-youtube-square"></i></a></li>
                            <li><a href="http://www.linkedin.com/company/astm-international?trk=cp_followed_name_astm-international" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/astmintl/" target="_blank"><i className="fab fa-instagram-square"></i></a></li>
                        </ul>
                    </div>
                    <div className="footerSearch">
                        <div className="searchWrap">
                            <HeaderSearchBox />
                        </div>
                    </div>
                </div>
                <div className="websiteAllLinks">
                    <ul>
                        <li><a href={process.env.REACT_APP_PUB_URL} target="_blank">Home</a> </li>
                        <li><a href={process.env.REACT_APP_PUB_URL + '/about/overview.html'} target="_blank">About</a> </li>
                        <li><a href={process.env.REACT_APP_PUB_URL + '/contact/'} target="_blank">Contact</a> </li>
                        <li><a href={process.env.REACT_APP_PUB_URL + '/policies.html'} target="_blank">Policies</a> </li>
                        <li><a href={process.env.REACT_APP_PUB_URL + '/privacy-policy'} target="_blank">Privacy Policy</a> </li>
                        <li><a href={process.env.REACT_APP_PUB_URL + '/copyright-and-permissions'} target="_blank">Copyright/Permissions</a></li>
                        <li><a href={process.env.REACT_APP_PUB_URL + '/products-services/reading-room.html'} target="_blank">Reading Room</a></li>
                    </ul>
                </div>
                <div className="copyrightInfo">
                    Copyright © 1996 - { new Date().getFullYear() } ASTM. All Rights Reserved. ASTM International, 100 Barr Harbor Drive, PO Box C700, West Conshohocken, PA, 19428-2959 USA
                </div>
            </div>


        </footer>
    );
}

export default withRouter(props => <Footer {...props} />);