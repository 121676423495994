export const ComponentUrls = {
   MyCommittees: '/MyASTM/MyCommittees',
   WorkItems: '/MyASTM/MyCommittees/WorkItems',
   MyWorkItems: '/MyASTM/MyCommittees/WorkItems/MyWorkItems',
   WorkItemDetails: '/MyASTM/MyCommittees/WorkItems/WorkItemDetails/',
   StandardsTracking: '/MyASTM/MyCommittees/StandardsTracking',
   StandardsTrackingDetails: '/MyASTM/MyCommittees/StandardsTracking/',
   SubmitMinutesAgenda: '/MyASTM/MyCommittees/SubmitMinutesAgenda',
   MeetingsSymposia: '/MyASTM/MyCommittees/MeetingsSymposia',
   MinutesAgenda: '/MyASTM/MyCommittees/MinutesAgenda',
   CommitteeDocument: '/MyASTM/MyCommittees/CommitteeDocument',
   MinutesAgendaClosingReportTracker: '/MyASTM/MyCommittees/tracker',
   DocumentsForReview: '/MyASTM/MyCommittees/DocumentsForReview',
   CommitteeDocumentsForReview: '/MyASTM/MyCommittees/DocumentsForReview/CommitteeDocumentsForReview',
   WorkItemApp: 'work-item',
   MyStudentMembership: '/MyASTM/Student',
   RequestUpgrade: '/MyASTM/Student/RequestUpgrade',
   SampleStandards: '/MyASTM/Student/SampleStandards',
   VirtualMeetings: '/MyASTM/MyCommittees/VirtualMeetings',
   InviteStudent: '/MyASTM/Student/InviteStudent',
   InviteProfessor: '/MyASTM/Student/InviteProfessor',
   InviteColleague: '/MyASTM/colleague',
   DownloadDocuments: '/download',
   StudentUpgrade: '/MyASTM/StudentUpgrade'
}

export const StaticPages = {
   CitingASTMStandards: '/Msgs/CitingAstmStandards',
   StandardsTracking: '/Msgs/StandardsTracking'
}

export const PublicUrls = [
'/v1/master/country',
'/v1/membership/stockyear',
'/v1/membertype',
'/v1/member/addressValidation',
'/v1/company/address',
'/v1/company/search',
'/v1/master/state',
'/v1/company/search',
'/v1/master/primaryactivity',
'/v1/master/committee',
'/v1/master/subcommittee',
'/v1/master/volume',
'/v1/membership/saveforminformation',
'/v1/master/featureflag',
'/v1/member/download/validate',
'/v1/member/download',
'/v1/S3/SecureS3SignedURL',
'/v1/emailverify'
]
