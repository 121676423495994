import types from './types';

const INITIAL_STATE = {}

const loaderReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.UPDATE_CLASS_AND_VOTE_LOADER: {
      return {
        ...state,
        [action.memberId]: {
          isShow: action.isShow,
          value: action.value,
          inputType: action.inputType
        }
      }
    }
    default:
      return state;
  };
}

export default loaderReducer;