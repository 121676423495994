import types from './types';

export const setEntitlement = (value) => ({
    type: types.SET_ENTITLEMENT,
    payload: value
});

export const setAppEntitlement = (value) => ({
    type: types.SET_APP_ENTITLEMENT,
    payload: value
});

export const setMenuMappedList = (value) => ({
    type: types.SET_MENU_MAPPED_LIST,
    payload: value
});