const SET_SUBCOMMITTEE_LIST_JOIN_DROP = 'SET_SUBCOMMITTEE_LIST_JOIN_DROP';
const RESET_REINSTATE_MEMBERSHIP_DATA = 'RESET_REINSTATE_MEMBERSHIP_DATA';
const SET_DROPPED_MAIN_COMMITTEES_DATA = 'SET_DROPPED_MAIN_COMMITTEES_DATA';
const SET_DROPPED_MAIN_COMMITTEES_ADDITIONAL_DATA = 'SET_DROPPED_MAIN_COMMITTEES_ADDITIONAL_DATA';
const SET_FUTURE_MEMBERSHIP_TYPE = 'SET_FUTURE_MEMBERSHIP_TYPE';
const RESET_FUTURE_MEMBERSHIP_TYPE = 'RESET_FUTURE_MEMBERSHIP_TYPE';
const SET_REINSTATE_MAIN_COMMITTEES = 'SET_REINSTATE_MAIN_COMMITTEES';
const SET_REINSTATE_VOLUME = 'SET_REINSTATE_VOLUME';
const SET_REINSTATE_FROM = 'SET_REINSTATE_FROM';

export default {
    SET_SUBCOMMITTEE_LIST_JOIN_DROP,
    RESET_REINSTATE_MEMBERSHIP_DATA,
    SET_DROPPED_MAIN_COMMITTEES_DATA,
    SET_DROPPED_MAIN_COMMITTEES_ADDITIONAL_DATA,
    SET_FUTURE_MEMBERSHIP_TYPE,
    RESET_FUTURE_MEMBERSHIP_TYPE,
    SET_REINSTATE_MAIN_COMMITTEES,
    SET_REINSTATE_VOLUME,
    SET_REINSTATE_FROM
}