import store from '../store';
import axios from './axios-config';

const currentVersion = "/v1"

export const Urls = {
  MemberLogin: "/memberlogin",
  verifyInfo: '/verify-info',
  RosterPermissions: "/member/rosterpermissions",
  MembershipStockYear: "/membership/stockyear",
  MembershipInformation: "/membertype/:membershipTypeId",
  MemberCommitteeProfile: "/member/committeeprofile",
  MemberActiveMainCommittee: "/member/activemaincommittees",
  MemberSubCommittee: "/member/subcommittees/:committeeId",
  AvailableMainCommittees: "/committee/availablemaincommittees",
  DropCommittees: "/committee/dropMemberFromCommittee",
  JoinCommittees: "/committee/joinmembertocommittee",
  committeeMembers: "/member/committeeMembers/:committeeId",
  MasterMainCommittee: "/master/committee",
  MasterSubCommittees: "/master/subcommittee/:committeeId/:isSpressedCommitteeRequired",
  MasterPrimaryActivity: "/master/primaryactivity/:membershiptypeId",
  VolumeList: "/master/volume",
  CompanySearch: "/company/search",
  CompanyAddressSearch: "/company/address",
  BookMovementList: "/member/bookmovement",
  CountryList: "/master/country",
  StateList: "/master/state/:countryCode",
  SaveMembershipFormData: "/membership/saveforminformation",
  CheckoutProcess: "/member/checkout",
  EntitlementList: "/entitlement/:accountNumber",
  MemberCommitteeInfo: "/member/getMemberCommitteeInfo/:accountNumber",
  MemberRecentActivities: "/member/recentactivities/:memberId/:maeUserId",
  ChangeOfEmploymentNotification: "/member/ChangeOfEmploymentNotification",
  MemberPrimaryActivityData: "/member/memberCommitteePrimaryActivities/:accountNumber",
  getCommitteeAndOfficerTitleDetail: "/member/getcommitteeandofficerdetail",
  MemberOfficerHistoryDetail: "/member/memberofficerhistorydetail",
  OKTAOpenAuth: "/openauth",
  // FaqList: "/v1/master/faq/:applicationId",
  // MembershipTypeList: "/v1/master/membershiptype/:applicationId",
  CollabAreaPermission: "/member/collabAreaPermission/:accountNumber",
  MyToolsMenuPermissions: "/member/myToolsMenuPermissions/",
  LoggedInMemberDetails: "/member/memberAccountDetail/",
  CommitteeWorkItemList: "/member/committeeWorkItemList/",
  WorkItemDetails: "/member/memberWorkItemDetail/",
  clearRedis: "/logout",
  standardsTrackingLinkData: "/standardsTracking/standardforCommittee",
  standardsTrackingDetails: "/standardsTracking/standardforCommittee/:committeeId/:committeeDesignation",
  updateDemographicInfo: "/member/updatedemographicinfo",
  getAllCommitteeList: "/tracker/AllCommitteeList/:accountNumber",
  getTrackerPreferencesList: "/tracker/TrackerPreferencesList/:accountNumber",
  updateTrackerPreferencesList: "/tracker/updateTrackerPreferencesList/:accountNumber",
  addressValidation: "/member/addressValidation",
  flagFeature: "/master/featureflag",
  bannerFeature: "/manageAlerts",
  
  //Minute&Agenda
  MemberCommitteeList: "/member/committeelist",
  ManageMinutes: "/minutes",
  ManageAgenda: "/agenda",
  getSignedUrlForFileUpload: "/ballot/getSignedUrl",
  viewMinutesAndAgenda: "/viewMinutesAndAgenda",
  viewAllMinutesAndAgenda: "/viewAllMinutesAndAgenda",
  viewDMSMinutesAndAgenda: "/viewMinutesAndAgenda/DMS",
  viewDMSAllMinutesAndAgenda: "/viewAllMinutesAndAgenda/DMS",

  // Meeting&Smposia
  MeetingSymposiaList: "/meetings/futureMeetingsSymposiaList/:committeeDesignation",

  //Committee Documents
  committeeDocuments: "/committeeDocuments",
  DMSCommitteeDocuments: "/committeeDocuments/dms",

  //Aventri
  BridgeAPI: '/aventri/bridgeAPI',

  // Documents for review
  reviewDocumentsSubcommittees: "/documentsForReview",
  reviewDocuments: "/committeeDocumentsForReview",

  virtualMeetingInitialData: "/virtualMeetings/initialData/:memberId/:memberAccountNumber",
  virtualMeetingSubCommitteeList: "/virtualMeetings/memberSubCommittees/:committeeId/:memberAccountNumber",
  virtualMeetingRosterMemberList: "/virtualMeetings/memberList/:mainCommitteeId/:subCommitteeId/:memberAccountNumber",
  virtualMeetingAddNonMember: "/virtualMeetings/nonMember",
  virtualMeetingAdd: "/virtualMeetings/virtualMeeting",
  getCollabAreaData: "/virtualMeetings/getCollabAreaData/:committeeDesignation",

  // Manage Committees
  GetTemplateSignedUrl: "/membership/template/:fileName",

  // Invitation
  inviteProfessorStudent: "/invitation",
  inviteManagerDetail: "/invitation/ManagerDetail",

  // Download from S3 routes
  downloadDocumentUrl: "/member/download", // public API Download from s3 directly via anchor tag
  validateDownloadRequest: "/member/download/validate", // Validate data from S3File table
  verifyDocExists: "/member/download/verify", // Verify from S3 if document is present
  secureS3SignedURL: "/S3/SecureS3SignedURL",  //get S3 signedurl if document is present

  // My Tool Right Menu Apis
  NegativeAndComment:"/member/getNegativeAndComment",
  OutstandingBallots:"/member/getOutstandingBallotsList/",
  NextMeeting:"/member/getNextMeetingList",
  AllWorkItem:"/member/getAllWorkItem/",
  CollabWorkItem:'/member/getCollabWorkItem/',
  CollabList:'/member/getCollabList/',

  // Document Search API
  searchDocumentRequest:"/member/searchDMSDocument",
  EmailInformation: "/emailverify/",
};

export const buildURL = (url, queryParam, paramReplacements, isOpen = false) => {
  const API_URL = isOpen ? process.env.REACT_APP_OPEN_API_URL : process.env.REACT_APP_API_URL;
  if (queryParam) {
    url = addQueryParameters(url, queryParam);
  }

  if (paramReplacements) {
    url = replaceParams(url, paramReplacements);
  }

  return API_URL + currentVersion + url;
};

const addQueryParameters = (url, params) => {
  const queryList = [];
  Object.keys(params).forEach(k => {
    queryList.push(`${k}=${encodeURIComponent(params[k])}`);
  });

  const prefixCharacter = url.indexOf("?") === -1 ? "?" : "&";

  return `${url + prefixCharacter + queryList.join("&")}`;
};

const replaceParams = (url, params) => {
  Object.keys(params).forEach(k => {
    url = url.replace(`:${k}`, encodeURIComponent(params[k]));
  });
  return url;
};

export const callAPI = (url, method, postData, isHeader, cb) => {
  const { app: appStore } = store.getState();
  let axiosObj = {
    baseURL: '',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Origin': '*',
    }
  }
  axios.defaults.headers.common['Is-Test-Mode'] = appStore.isTestMode;
  method = method || "get";

  axios[method](url, postData, axiosObj).then(
    res => {
      cb && cb(res.data);
    },
    err => {
      cb && cb(null);
    }
  ).catch(error => {
    cb && cb(null);
  });
};

export const uploadFile = (req, callback) => {
  axios.put(req.url, req.file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(null);
    });
}
