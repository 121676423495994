import React from 'react';
import { useHistory, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';
import { useOktaAuth } from '@okta/okta-react';
import { setUserInfo } from '../../../redux/login/actions';
import { redirectUrlHandler, OktaSecurityHOC, handleLoggedOut } from "../../../helpers/OktaUtility";
import { APP_ROOT_CONTENT_STYLE } from '../../../helpers/constant';
import { setCookie } from '../../../helpers/manageCookies';
import { getUserInfo } from '../../../helpers/utilCommon';
import { LOGIN_USER_MODEL, cookieKeysEnum } from '../../../models/common.models';
import { PrivateRoutes, PublicRoute } from '../../../hoc/RouteHOC';
import asyncComponent from '../../../asyncComponent';
import { withFlagFeature } from 'providers/flagFeature';
import FooterComp from '../../organisms/Footer';
import HeaderComp from '../../organisms/Header';
import AlertBanner from '../../organisms/AlertBanner';
import IdentityDelegationBanner from '../../organisms/IdentityDelegationBanner';
import SafariAutofillMessage from '../../atoms/SafariAutofillMessage';
import CustomLoader from '../../atoms/CustomLoader';
import ScrollToTop from '../../atoms/ScrollToTop';
import ToastMessage from '../../atoms/ToastMessage';
import AventriRegistration from '../aventri';
import MyChildAppComponents from '../myChildAppComponents';
import MyRosterComponents from './RosterChildApp';

const MyAccountRoutes = asyncComponent(() => {
    return import('../../../chunks/myAccount.chunk');
});

const DownloadDocumentsRoutes = asyncComponent(() => {
    return import('../../../chunks/downloadDocuments.chunk');
});

const ApplicationsRoutes = asyncComponent(() => {
    return import('../../../chunks/applications.chunk');
});

const StaticPagesRoutes = asyncComponent(() => {
    return import('../../../chunks/staticPages.chunk');
});

const App = (props) => {
    const { isSignOutTrigger, isAppLoading, isMiniAppLoading, showToastMessage, content, uuidNumber, isReactTesting } = props;
    const { authState } = useOktaAuth();
    const dispatch = useDispatch();
    const userInfo = useSelector(store => store.user.userInfo);
    const [isOktaInitializing, setIsOktaInitializing] = React.useState(true);
    const [isMemberStillLoginInAnotherTab, setIsMemberStillLoginInAnotherTab] = React.useState('');
    const history = useHistory()

    React.useEffect(() => {
        window.addEventListener("storage", (e) => {
            if (e.key === 'isMemberStillLoginInAnotherTab' && e.newValue === 'false') {
                handleLoggedOut(dispatch)
            }
        });
    }, [])

    React.useEffect(() => {
        if (authState) {
            if (authState.isAuthenticated) {
                setIsOktaInitializing(true);
                const callbackUrl = redirectUrlHandler();
                if (callbackUrl) {
                   history.push(callbackUrl)
                   sessionStorage.removeItem("REDIRECT_URL")
                }
                handleMemberLogin();
            } else {
                setIsOktaInitializing(false);
            }
        }
    }, [authState]);
    

    React.useEffect(() => {
        if (userInfo) {
            datadogLogs.setUser({
                MAEUserID: userInfo.MAEUserID,
                memberAccountNumber: userInfo.memberAccountNumber
            });
        } else {
            datadogLogs.clearUser();
        }
    }, [userInfo]);

    const handleMemberLogin = () => {
        if (authState && authState.isAuthenticated && authState.idToken?.claims) {
            const storedUserInfo = getUserInfo();
            if (!storedUserInfo) {
                setCookie(cookieKeysEnum["IS-OKTA-VERIFIED"], true, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
                let data = {
                    userInfo: authState.idToken.claims,
                    request_mode: LOGIN_USER_MODEL.MEMBER_LOGIN,
                };
                props.handleMemberLogin(data, result => {
                    setIsOktaInitializing(false);
                });
            } else {
                dispatch(setUserInfo(storedUserInfo));
                setIsOktaInitializing(false);
            }
        }
    }

    return (
        <>
            {isMiniAppLoading ? <CustomLoader testId='mini-spinner' /> : null}
            {isAppLoading || isSignOutTrigger || isOktaInitializing ? <CustomLoader testId='app-spinner' /> : null}
            {showToastMessage ? (<div className="topAlert"><ToastMessage content={content} uuidNumber={uuidNumber} /></div>) : null}
            <ScrollToTop />
            <SafariAutofillMessage />
            <AlertBanner />
            {!isReactTesting && <HeaderComp />}
            <IdentityDelegationBanner />
            <section style={APP_ROOT_CONTENT_STYLE}>
                {!(isSignOutTrigger || isOktaInitializing) &&
                    <Switch>
                        <PublicRoute path='/application' component={ApplicationsRoutes} />
                        <PublicRoute path='/Msgs' component={StaticPagesRoutes} />
                        <PublicRoute path='/aventri-registration' component={AventriRegistration} />
                        <PublicRoute path='/download' component={DownloadDocumentsRoutes} />
                        <PrivateRoutes path="/MyASTM" component={MyAccountRoutes} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/ballot" component={MyChildAppComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/wi" component={MyChildAppComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/standard" component={MyChildAppComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/meeting" component={MyChildAppComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <PrivateRoutes path="/RMLogin" component={MyRosterComponents} isMemberStillLoginInAnotherTab={isMemberStillLoginInAnotherTab} />
                        <Redirect from='/login' to="/MyASTM" />
                        <Redirect excat from='/' to="/MyASTM" />
                        <Route path='*' component={(props) => {
                            const { pathname } = props.location;
                            if (pathname !== '/auth') {
                                window.open(process.env.REACT_APP_PUB_404_URL, "_self");
                            }
                            return false;
                        }} />
                    </Switch>
                }
            </section>
            <FooterComp />
        </>
    );
}

const RouterApp = (props) => {
    return (
        <OktaSecurityHOC>
            <App {...props} />
        </OktaSecurityHOC>
    );
}

export default withFlagFeature(withRouter(RouterApp));