import React, { useEffect, useState }  from 'react';
import { assign } from 'lodash';
import { getUserInfo } from 'helpers/utilCommon';

export const IdentityDelegationLink = ({ ...props }) => {
    
    const [userInfo, setUserInfo] = useState(null);
    useEffect(() => {
        setUserInfo(getUserInfo());
    }, []);

    const customProps = assign(props, {
        id: props.id ? props.id : props.name ? props.name : "identity-delegation-link",
        className: userInfo?.IdentityDelegation?.permission === 'view'
            ? (`${props.identityDelegationClass} astm-identity-delegation-link`)
            : (`${props.className} astm-link`)
    });

    
    return ( <>
        { userInfo?.IdentityDelegation?.permission === 'view' 
        ? <span {...customProps} >{props?.children}</span>
        : <a {...customProps} >{props?.children}</a>}
    </>
);
}