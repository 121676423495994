import { datadogLogger, DATADOG_LOG_TYPE } from "helpers/utilCommon";
import React from "react";

class ErrorBoundary extends React.Component {
    componentDidCatch = (error) => {
        datadogLogger(
            `Error Boundary: ${error}`,
            {},
            DATADOG_LOG_TYPE.error,
            error
        );
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;