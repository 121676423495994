import types from './types';

const INITIAL_STATE = {
  meetingSymposiaList: [],
}

const meetingSymposiaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_MEETINGS_SYMPOSIA_LIST: {
      return {
        ...state,
        meetingSymposiaList: [...state.meetingSymposiaList, action.payload]
      }
    }
    default: return state;
  }
}

export default meetingSymposiaReducer;