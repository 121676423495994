import React from 'react';

class MyRosterComponents extends React.Component {
    state = {}

    componentDidMount = () => {
        window.location.assign(process.env.REACT_APP_ROSTER_URL);
    }

    render = () => {
        return null;
    }
}

export default MyRosterComponents;