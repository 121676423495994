import types from './types';

const initialState = {
  code: null,
  showErrorPopup: false,
  reduxFormData: null,
  uuidNumber: null
};

const reducerShared = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MESSAGE:
      return {
        ...state,
        code: action.code,
        showToastMessage: action.flag,
        uuidNumber: action.uuidNumber ? action.uuidNumber : null
      };

    case types.REDUX_FORM_DATA: {
      return {
        ...state,
        reduxFormData: action.data
      }
    }

    default:
      return state;
  }
};

export default reducerShared;