import { get } from 'lodash';
import { connect } from 'react-redux';
import { checkUser } from '../../../services/appServices';
import { memberLoginAction } from '../../../services/userServices';
import App from './AppComponent';

const mapStateToProps = (state) => {
  const { user, app, i18nReducer, shared } = state;
  return {
    isAppLoading: app.isAppLoading,
    isMiniAppLoading: app.isMiniAppLoading,
    showToastMessage: shared.showToastMessage,
    content: get(i18nReducer.dictionaries[i18nReducer.currentLanguage], shared.code),
    isSignOutTrigger: user.isSignOutTrigger,
    uuidNumber: shared.uuidNumber
  }
};


const mapDispatchToProps = dispatch => {
  let actions = {
    checkUser: () => dispatch(checkUser()),
    handleMemberLogin: (data, cb) => memberLoginAction(data, dispatch, cb),
  }
  return { ...actions, dispatch };
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;