import * as type from './types';

const initialState = {
  trackerPreferencesList: {}
};

const reducerTrackerPreferences = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_TRACKER_PREFERENCES_LIST:
      return {
        ...state,
        trackerPreferencesList: action.payload
      }

    default:
      return state;
  }
};

export default reducerTrackerPreferences;
