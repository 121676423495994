import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const CustomTooltip = (props) => {
    return (
        <OverlayTrigger
            key={props.title}
            placement={props.placement}
            overlay={<Tooltip data-testid="tooltip-component" id={props.title}>{props.title}</Tooltip >}
        >
            {props.HTMLElement}
        </OverlayTrigger>
    )
}
export default CustomTooltip
