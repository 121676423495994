import { getUserAgent } from "helpers/utilCommon";
import React, { useEffect, useState } from "react";

const SafariAutofillMessage = ({ ...props }) => {

    const [stateHandler, setStateHandler] = useState({ isShow: false });
    useEffect(() => {
        const { name } = getUserAgent();
        if (name === 'Safari') setStateHandler({ isShow: true });
        else setStateHandler({ isShow: false });
    }, [])
    return (
        stateHandler && stateHandler.isShow ?
            <div className="alert astm-alert bg-info--light" role="alert">
                <span className="astm-alert-text">We are assuming that Autofill settings are turned on for Safari browser (Safari > Preferences > AutoFill). Please turn off the settings for the best site experience or use a different browser.</span>
                <button type="button" onClick={(e) => setStateHandler({ isShow: false })} className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true"><i className="astm-icon far fa-times"></i></span>
                </button>
            </div> : null
    )
}




export default SafariAutofillMessage;