import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nReducer } from 'redux-react-i18n';
import reducerStandardsTracking from './redux/standardsTrackingReducer/reducers';
import tracker from './redux/trackerReducer/reducers';
import appReducer from './redux/app/reducers';
import committeeRoster from './redux/committeeRoster/reducers';
import loaderReducer from './redux/editLoader/reducers';
import entitlement from './redux/entitlement/reducers';
import loginReducer from './redux/login/reducers';
import meetingSymposia from './redux/meetingsymposia/reducers';
import membershipReducer from './redux/membership/reducers';
import membershipApplication from './redux/membershipApplication/reducers';
import reducerShared from './redux/shared/reducers';
import virtualMeeting from './redux/virtualMeetingsReducer/reducers';

const rootReducer = combineReducers({
  i18nReducer,
  form: formReducer,
  user: loginReducer,
  app: appReducer,
  shared: reducerShared,
  loaders: loaderReducer,
  membership: membershipReducer,
  membershipApplication,
  committeeRoster,
  entitlement,
  reducerStandardsTracking,
  tracker,
  meetingSymposia,
  virtualMeeting
});

export default rootReducer;