import { cookieKeysEnum } from '../models/common.models';


export const encrypt = (text, REACT_APP_STORAGE_IV_LENGTH, REACT_APP_STORAGE_VALUE) => {
    try {
        if (text) {
            let iv = crypto.randomBytes(Number(REACT_APP_STORAGE_IV_LENGTH));
            let cipher = crypto.createCipheriv('aes-256-cbc', new Buffer(REACT_APP_STORAGE_VALUE), iv);
            let encrypted = cipher.update(text);
            encrypted = Buffer.concat([encrypted, cipher.final()]);
            return iv.toString('hex') + ':' + encrypted.toString('hex');
        }
        return text;
    } catch (e) {
        console.log("Exception in Encryption", e);
    }
};

export const decrypt = (text, REACT_APP_STORAGE_VALUE) => {
    try {
        if (text) {
            let textParts = text.split(':');
            if (textParts && textParts.length > 0) {
                let iv = new Buffer(textParts.shift(), 'hex');
                let encryptedText = new Buffer(textParts.join(':'), 'hex');
                let decipher = crypto.createDecipheriv('aes-256-cbc', new Buffer(REACT_APP_STORAGE_VALUE), iv);
                let decrypted = decipher.update(encryptedText);
                decrypted = Buffer.concat([decrypted, decipher.final()]);
                return decrypted.toString();
            }
        }
        return text;
    } catch (e) {
        console.log("Exception in Decryption", e);
    }
};


export const getDomain = () => {
    // TODO : Get Parent Domain from current domain.
    if (document.domain && document.domain.length) {
        let parts = document.domain.replace(/^(www\.)/, "").split('.');
        while (parts.length > 2) {
            let subdomain = parts.shift();
        }
        let domain = parts.join('.');
        return domain.replace(/(^\.*)|(\.*$)/g, "");
    } // GET Default Doamin.
    return document.domain;
}

export const setCookie = (cookieName, token, toExpire = 1, isHours = true) => {
    let date = new Date();
    if (isHours) {
        date.setTime(date.getTime() + (toExpire * 60 * 60 * 1000));
    } else {
        date.setTime(date.getTime() + (toExpire * 60 * 1000));
    }
    let secureFlag = window.location.hostname !== 'localhost' ? ';secure' : '';
    document.cookie = cookieName + "=" + token + "; expires=" + date.toUTCString() + ";domain=" + getDomain() + ";path=/" + secureFlag;
};

export const removeCookie = (cookieName) => {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=" + getDomain() + ";path=/";
};

export const deleteAllCookies = () => {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + getDomain() + ";path=/";
    }
}

export const removeAppCookies = (isBeforeLogin = true) => {
    for (let key in cookieKeysEnum) {
        if (isBeforeLogin && (key == "REDIRECT-URL")) {
            continue;
        } else {
            removeCookie(cookieKeysEnum[key]);
        }
    }
}

export const getCookie = (cookieName) => {
    const name = cookieName + "=";
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
        const temp = allCookieArray[i].trim();
        if (temp.indexOf(name) === 0) {
            return temp.substring(name.length, temp.length);
        }
    }
    return "";
}

export const checkCookie = (cookieName) => {
    const name = cookieName + "=";
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
        const temp = allCookieArray[i].trim();
        if (temp.indexOf(name) == 0) {
            return true;
        }
    }
    return false;
}

export const setEncryptedCookie = (coockieObject) => {
    let { cookieName, cookieValue, toExpire, isHours, isEncr, encrKey, encrLength } = coockieObject;
    toExpire = toExpire ? toExpire : 1;
    isHours = isHours ? isHours : true;
    isEncr = isEncr ? isEncr : false;
    encrLength = encrLength ? encrLength : 16;
    if (!cookieName) {
        console.log('set cookies error cookieName not found.');
    }
    else if (!cookieValue) {
        console.log('set cookies error cookieValue not found.');
    }
    else if (isEncr) {
        if (!encrKey) {
            console.log('set cookies error encryption key not found.');
        }
        else {
            setCookie(cookieName, encrypt(cookieValue, encrLength, encrKey), toExpire, isHours);
        }
    }
    else {
        setCookie(cookieName, cookieValue, toExpire, isHours);
    }
}

export const getEncryptedCookie = (coockieObject) => {
    let { cookieName, isEncr, encrKey } = coockieObject;
    isEncr = isEncr ? isEncr : false;
    if (!cookieName) {
        console.log('set cookies error cookieName not found.');
    }
    else if (isEncr) {
        if (!encrKey) {
            console.log('set cookies error encryption key not found.');
        }
        else {
            return decrypt(getCookie(cookieName), encrKey);
        }
    }
    else {
        return getCookie(cookieName);
    }
    return "";
}

export default {
    setCookie,
    removeCookie,
    deleteAllCookies,
    getCookie,
    checkCookie,
    encrypt,
    decrypt,
    setEncryptedCookie,
    getEncryptedCookie,
    removeAppCookies
};