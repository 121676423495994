import React from 'react';
import { useDispatch } from 'react-redux';
import { flagFeature } from 'services/appServices';

export const FlagFeaturesContext = React.createContext({
    isInitializing: true,
    getFlagFeatures: (key) => {}
});

export const FlagFeaturesProvider = React.memo(flagFeaturesProps => {
    const dispatch = useDispatch();
    const [isInitializing, setIsInitializing] = React.useState(true);
    const [flagFeatures, setFlagFeatures] = React.useState(null);

    React.useEffect(() => {
        dispatch(flagFeature((featureResponse) => {
            setIsInitializing(false);
            setFlagFeatures(featureResponse)
        }));
    }, [dispatch]);

    const flagFeaturesValues = React.useMemo(() => {
        return {
            isInitializing,
            getFlagFeatures: (key) => {
                if(flagFeatures && flagFeatures[key]) {
                    return flagFeatures[key];
                }
                return false;
            }
        };
    }, [isInitializing, flagFeatures]);

    return <FlagFeaturesContext.Provider value={flagFeaturesValues}>{flagFeaturesProps.children}</FlagFeaturesContext.Provider>;
});

export const useFlagFeatures = () => React.useContext(FlagFeaturesContext);

export const withFlagFeature = (Component) => {
    return function WrappedComponent(props) {
        const flagFeature = useFlagFeatures();
        return <Component  {...props} flagFeature={flagFeature} />
    }
}