export const SET_IS_LOADING_DATA = 'SET_IS_LOADING_DATA';
export const SET_ALL_STANDARDS_TRACKING_DATA = 'SET_ALL_STANDARDS_TRACKING_DATA';
export const CLEAR_STANDARDS_TRACKING_DATA = 'CLEAR_STANDARDS_TRACKING_DATA';
export const CLEAR_STANDARDS_TRACKING_API_STATUS = 'CLEAR_STANDARDS_TRACKING_API_STATUS';

export default {
    SET_IS_LOADING_DATA,
    SET_ALL_STANDARDS_TRACKING_DATA,
    CLEAR_STANDARDS_TRACKING_DATA,
    CLEAR_STANDARDS_TRACKING_API_STATUS
}