import React, { Component } from 'react';

class MyChildAppComponents extends Component {

   state = {
    
   }

   componentDidMount() {
      window.location.assign(this.props.location.pathname)
   }

   render() {
      return (
        <></>
      )
   }
}

export default MyChildAppComponents;


