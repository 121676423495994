import { connect } from 'react-redux';
import { postDataToBridgeAPI } from '../../../services/aventriService';
import RegistraionComponent from './RegistraionComponent';

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo
})

const mapDispatchToProps = dispatch => ({
    postDataToBridgeAPI: (data, callback) => { postDataToBridgeAPI(data, dispatch, callback) }
})

const RegistraionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistraionComponent);

export default RegistraionContainer;