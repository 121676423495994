import { buildURL, callAPI, Urls } from "../helpers/apiUrls";
import { setMiniAppLoader } from '../redux/app/actions';
import { setMessage } from '../redux/shared/actions';
import { getUUIDNumberFromLocalStorage } from '../helpers/utilCommon';

export const postDataToBridgeAPI = (data, dispatch, callback) => {
    dispatch(setMiniAppLoader(true));
    const url = buildURL(Urls.BridgeAPI, null, null)
    callAPI(url, 'post', data, true, (response) => {
        if (response && response.bridgeid) {
            callback(response.bridgeid);
        } else {
            const uuidNumber = getUUIDNumberFromLocalStorage(url);
            dispatch(setMessage(true, '4003', uuidNumber));
            dispatch(setMiniAppLoader(false));
        }
    });
}
