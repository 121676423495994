import { deepCopy } from '../../helpers/constant';
import types from './types';

const INITIAL_STATE = {
  messageObj: {},
  userInfo: null,
  memberData: null,
  demoGraphy: {},
  userPermission: null,
  isPermissionLoader: false,
  isSignOutTrigger: false,
  loggedInMemberData: {}
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER_INFO: {
      const { payload } = action;
      return {
        ...state,
        userInfo: payload
      }
    }
    case types.SET_USER_PERMISSION: {
      const { payload } = action;
      return {
        ...state,
        userPermission: payload,
        isPermissionLoader: false
      }
    }
    case types.SET_USER_PERMISSION_LOADER: {
      const { payload } = action;
      return {
        ...state,
        isPermissionLoader: payload
      }
    }
    case types.SET_LOGIN_MESSAGE: {
      const { payload } = action;
      return {
        ...state,
        messageObj: payload
      }
    }
    case types.SIGN_OUT: {
      const { payload } = action;
      return {
        ...state,
        isSignOutTrigger: payload
      };
    }
    case types.SET_MEMBER_DATA: {
      const { payload } = action;
      return {
        ...state,
        memberData: payload
      };
    }
    case types.SET_DEMO_GRAPHY: {
      const { payload } = action;
      return {
        ...state,
        demoGraphy: payload
      };
    }
    case types.UPDATE_NICK_NAME: {
      const { payload } = action;
      let updatedDemography = deepCopy(state.demoGraphy);
      updatedDemography.NickName = payload;
      return {
        ...state,
        demoGraphy: updatedDemography
      };
    }
    case types.SET_LOGGED_IN_MEMBER_DATA: {
      const { payload } = action;
      return {
        ...state,
        loggedInMemberData: payload
      };
    }
    default: return state;
  }
}

export default loginReducer;