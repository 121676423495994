import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { useSelector } from 'react-redux';
import { map, isEmpty, forEach, cloneDeep } from 'lodash';
import { Accordion, Card, NavDropdown } from 'react-bootstrap';
import onClickOutside from "react-onclickoutside";

const navJSON = [{
    title: "Products and Services",
    subLinks: [{
        title: "Standards & Publications",
        subLinks: [{
            title: "All Standards and Publications",
            link: process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications.html'
        }, {
            title: "Standards Products",
            link: process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/standards.html'
        }, {
            title: "Symposia Papers & STPs",
            link: process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/symposia-papers.html'
        }, {
            title: "Manuals, Monographs, & Data Series",
            link: process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/mnl.html'
        }, {
            title: "Technical Reports",
            link: process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/technical-reports.html'
        }, {
            title: "Journals",
            link: process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/journals.html'
        }, {
            title: "Reading Room",
            link: process.env.REACT_APP_PUB_URL + '/products-services/reading-room.html'
        }, {
            title: "Authors",
            link: process.env.REACT_APP_PUB_URL + '/products-services/digital-library.html'
        }]
    }, {
        title: "Book of Standards",
        link: process.env.REACT_APP_PUB_URL + '/products-services/bos.html'
    }, {
        title: "Reading Room",
        link: process.env.REACT_APP_PUB_URL + '/products-services/reading-room.html'
    }, {
        title: "Tracker Services",
        link: process.env.REACT_APP_PUB_URL + '/products-services/tracker-services.html'
    }, {
        title: "Catalogs",
        link: process.env.REACT_APP_PUB_URL + '/products-services/catalogs.html'
    }, {
        title: "Digital Library",
        link: process.env.REACT_APP_PUB_URL + '/products-services/digital-library.html'
    }, {
        title: "Enterprise Solutions",
        subLinks: [{
            title: "Integrated Services",
            link: process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/integrated-services.html'
        }, {
            title: "ASTM Compass®",
            link: process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/astm-compass.html'
        }, {
            title: "SpecBuilder",
            link: process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/specbuilder.html'
        }, {
            title: "Custom Platforms",
            link: process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/custom-platforms.html'
        }]
    }, {
        title: "Proficiency Testing",
        link: process.env.REACT_APP_PUB_URL + '/products-services/proficiency-testing.html'
    }, {
        title: "Training Courses",
        subLinks: [{
            title: "All Training Courses",
            link: process.env.REACT_APP_PUB_URL + '/products-services/training-courses.html'
        }, {
            title: "International Training",
            link: process.env.REACT_APP_PUB_URL + '/products-services/training-courses/international-training.html'
        }, {
            title: "PE Continuing Education",
            link: process.env.REACT_APP_PUB_URL + '/products-services/training-courses/pe-continuing-education.html'
        }, {
            title: "Member and Officer Training",
            link: process.env.REACT_APP_PUB_URL + '/products-services/training-courses/member-training.html'
        }]
    }, {
        title: "Certification",
        link: process.env.REACT_APP_PUB_URL + '/products-services/certification.html'
    }, {
        title: "Cement & Concrete Reference Lab",
        link: process.env.REACT_APP_PUB_CEMENT_REFERENCE_LAB
    }]
}, {
    title: "Get Involved",
    subLinks: [{
        title: "Technical Committees",
        link: process.env.REACT_APP_PUB_URL + '/get-involved/technical-committees.html'
    }, {
        title: "Membership",
        link: process.env.REACT_APP_PUB_URL + '/get-involved/membership.html'
    }, {
        title: "Meetings & Symposia",
        link: process.env.REACT_APP_GLOBAL_MEETING_SYMPOSIA_URL
    }, {
        title: "Students &amp; Professors",
        link: process.env.REACT_APP_PUB_URL + '/get-involved/students-and-professors/for-students/memstudent22.html'
    }, {
        title: "Publish With ASTM",
        link: process.env.REACT_APP_PUB_URL + '/get-involved/publish-with-astm.html'
    }
    ]
}, {
    title: "About",
    subLinks: [{
        title: "Overview",
        subLinks: [{
            title: "Overview",
            link: process.env.REACT_APP_PUB_URL + '/about/overview.html'
        }, {
            title: "Detailed Overview",
            link: process.env.REACT_APP_PUB_URL + '/about/overview/detailed-overview.html'
        }, {
            title: "Fact Sheet",
            link: process.env.REACT_APP_PUB_URL + '/about/overview/fact-sheet.html'
        }, {
            title: "White Papers",
            link: process.env.REACT_APP_PUB_URL + '/about/overview/white-papers.html'
        }, {
            title: "Corporate Citizenship",
            link: process.env.REACT_APP_PUB_URL + '/about/overview/corporate-citizenship.html'
        }]
    }, {
        title: "Governance",
        link: process.env.REACT_APP_PUB_URL + '/about/governance.html'
    }, {
        title: "Global Cooperation Home",
        link: process.env.REACT_APP_PUB_URL + '/about/global-cooperation.html'
    }, {
        title: "President's Column",
        link: process.env.REACT_APP_PUB_SN_LINK + '/?q=presidents-column'
    }, {
        title: "Diversity, Equity, and Inclusion",
        link: process.env.REACT_APP_PUB_URL + '/about/diversity-inclusion.html'
    }]
}, {
    title: "News",
    subLinks: [
        {
            title: "Standardization News",
            link: process.env.REACT_APP_PUB_SN_LINK
        }, {
            title: "News Releases",
            link: process.env.REACT_APP_PUB_NEWSROOM_LINK
        },
    ]
}];

const navJSONAfterDynamicLinks = [{
    title: "Contact",
    link: process.env.REACT_APP_PUB_URL + '/contact/'
}, {
    title: "Cart",
    link: process.env.REACT_APP_PUB_URL + '/checkout/cart/'
}];

const renderAccordian = (list) => {
    return map(list, (item) => {
        if (!isEmpty(item.subLinks)) {
            return (
                <Card key={item.title} className="astm-card">
                    <Accordion.Toggle as={Card.Header} eventKey={item.title}>
                        <h2 className="mb-0">
                            <button className="btn astm-btn btn-link">
                                {item.title} <i className="fas fa-caret-right astm-icon"></i>
                            </button>
                        </h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={item.title}>
                        <Card.Body key={item.title}>
                            <Accordion>
                                {renderAccordian(item.subLinks)}
                            </Accordion>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            );
        }
        return (
            <Card key={item.title} className="astm-card">
                <Accordion.Toggle as={Card.Header} eventKey={item.title}>
                    <h2 className="mb-0">
                        <button className="btn astm-btn btn-link">
                            {item.click ? (
                                <a href={item.link} onClick={() => { RenderMobileMenu.handleCustomClickFunction(item.click); }}>{item.title}</a>
                            ) : item.link && item.link.toLowerCase().indexOf("/myastm") === 0 ? (
                                <a href="javascript:void(0);" onClick={() => { RenderMobileMenu.handleRedirectWithinMemberApp(item.link); }}>{item.title}</a>
                            ) : (
                                <a href={item.link} onClick={RenderMobileMenu.handleClickOutside}>{item.title}</a>
                            )}
                        </button>
                    </h2>
                </Accordion.Toggle>
            </Card>
        );
    });
}

function RenderMobileMenu(props) {
    const history = useHistory();
    const [isOpenMobileMenu, setOpenMobileMenu] = useState(false);

    const mergedJSON = cloneDeep(navJSON);
    const mergedJSONAfterDynamicLinks = cloneDeep(navJSONAfterDynamicLinks);
    const { menuList } = useSelector(state => state.entitlement);

    if (!isEmpty(menuList) && !isEmpty(menuList.main)) {
        forEach(menuList.main, (item) => {
            mergedJSON.push(item);
        });
    }

    if (!isEmpty(mergedJSONAfterDynamicLinks)) {
        forEach(mergedJSONAfterDynamicLinks, (item) => {
            mergedJSON.push(item);
        });
    }

    const handleMobileMenu = (isOpen) => {
        setOpenMobileMenu(isOpen);
        if (isOpen) {
            const body = document.getElementsByTagName("body")[0];
            body.classList.add("no-scroll");
        } else {
            const body = document.getElementsByTagName("body")[0];
            body.classList.remove("no-scroll");
        }
    }

    RenderMobileMenu.handleClickOutside = () => {
        handleMobileMenu(false);
    }

    RenderMobileMenu.handleRedirectWithinMemberApp = (link) => {
        handleMobileMenu(false);
        history.push(link);
    }

    RenderMobileMenu.handleCustomClickFunction = (click) => {
        handleMobileMenu(false);
        click();
    }

    const handleOverlayClick = (e) => {
        if (e && e.target && e.target.id === "mobile-overlay") {
            handleMobileMenu(false);
        }
    }

    return (
        <NavDropdown
            onToggle={handleMobileMenu}
            show={isOpenMobileMenu}
            title="Menu"
            id="mobile-menu-dropdown"
            className="mobileMenu"
            data-testid="mobile-header"
            onClick={(e) => props.hideSiteSearchFlag(e)}>
            <div id="mobile-overlay" className="commonLoader" onClick={(e) => { handleOverlayClick(e); }}>
                <Accordion className="astm-accordion astm-accordion--full-width">
                    {renderAccordian(mergedJSON)}
                </Accordion>
            </div>
        </NavDropdown>
    );
}

const clickOutsideConfig = {
    handleClickOutside: () => RenderMobileMenu.handleClickOutside
};

export default onClickOutside(RenderMobileMenu, clickOutsideConfig);
