import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import ReduxPromise from "redux-promise";
import { i18nActions } from 'redux-react-i18n';
import thunk from 'redux-thunk';
import en from './assets/i18n/en';
import rootReducer from './reducers';
import { isLoggerEnabled } from './helpers/utilCommon';

const dictionaries = {
    'en-US': en,
};

const languages = [
    {
        code: 'en-US',
        name: 'English (USA)',
    },
];

// TODO : validate logger enabled/disabled
const middleware = () => {
    if (isLoggerEnabled()) {
        return applyMiddleware(thunk, logger, ReduxPromise);
    }
    return applyMiddleware(thunk, ReduxPromise);
}



const store = createStore(rootReducer, composeWithDevTools(middleware()));
store.dispatch(i18nActions.setDictionaries(dictionaries));
store.dispatch(i18nActions.setLanguages(languages));
store.dispatch(i18nActions.setCurrentLanguage('en-US'));
export default store;