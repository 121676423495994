const SET_USER_INFO = 'SET_USER_INFO';
const SET_USER_PERMISSION = 'SET_USER_PERMISSION';
const SET_USER_PERMISSION_LOADER = 'SET_USER_PERMISSION_LOADER';
const SET_LOGIN_MESSAGE = 'SET_LOGIN_MESSAGE';
const SIGN_OUT = 'SIGN_OUT';
const SET_MEMBER_DATA = 'SET_MEMBER_DATA';
const SET_DEMO_GRAPHY = 'SET_DEMO_GRAPHY';
const UPDATE_NICK_NAME = 'UPDATE_NICK_NAME';
const SET_LOGGED_IN_MEMBER_DATA = 'SET_LOGGED_IN_MEMBER_DATA';

export default {
    SET_USER_INFO,
    SET_USER_PERMISSION,
    SET_USER_PERMISSION_LOADER,
    SET_LOGIN_MESSAGE,
    SIGN_OUT,
    SET_MEMBER_DATA,
    SET_DEMO_GRAPHY,
    UPDATE_NICK_NAME,
    SET_LOGGED_IN_MEMBER_DATA
}