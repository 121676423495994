import * as type from './types';

const initialState = {
  meetingList: [],
  workItemList: [],
  isInitialDataLoaded: false,
  subCommitteeList: [],
  initialValues: {},
  rosterAndNonMemberList: {
    MainCommitteeMembers: [],
    SubCommitteeMembers: [],
    NonMemberList: []
  }
};

const formatSubCommitteeList = (list) => {
  let result = []
  result.push({ key: -1, text: 'Main Committee Meeting Only', value: -1, committeeDesignation: 'Main Committee Meeting Only' })
  if (list && list.length > 0) {
    list.forEach(item => {
      result.push({ key: item.SubCommitteeId, text: `${item.CommitteeDesignation} - ${item.Title}`, value: item.SubCommitteeId, committeeDesignation: item.CommitteeDesignation })
    });

    return result;
  }

  return result;
}

const formatMeetingList = (list) => {
  list.map((item) => {
    item.key = item.VirtualMeetingId;
    item.value = `${item.VirtualMeetingId}`;
    item.text = item.Topic;
  })

  return list;
}

const formatWorkItemList = (list) => {
  let result = [];

  list.forEach(item => {
    let text = '';

    if (item.WorkItemTypeId === 1) {
      text = `${item.WorkItemNumber} Standard ${item.StandardTypeName} ${item.Title}`;
    } else {
      const name = item.RevisedStandardName || '';
      text = item.WorkItemNumber + ' Revision of ' + name;
    }
    result.push({
      key: item.WorkItemId, text: text, value: `${item.WorkItemId}`, workItemNumber: item.WorkItemNumber,
      mainCommitteeId: item.MainCommitteeId, mainCommitteeTitle: item.MainCommitteeTitle, mainCommitteeDesignation: item.MainCommitteeDesignation,
      subCommitteeId: item.SubCommitteeId, subCommitteeTitle: item.SubCommitteeTitle, subCommitteeDesignation: item.SubCommitteeDesignation
    })
  });

  return result;
}

const reducerVirtualMeeting = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_SUB_COMMITTEE_LIST:
      return {
        ...state,
        subCommitteeList: formatSubCommitteeList(action.payload)
      }
    case type.SET_INITIAL_DATA_VIRTUAL_MEETING:
      return {
        ...state,
        meetingList: formatMeetingList(action.payload.VirtualMeetingList),
        workItemList: formatWorkItemList(action.payload.WorkItemList),
        subCommitteeList: [],
        isInitialDataLoaded: true
      }
    case type.UPDATE_INITIAL_VALUES:
      return {
        ...state,
        initialValues: action.payload
      }
    case type.RESET_INITIAL_VALUES:
      return {
        ...state,
        initialValues: {}
      }
    default:
      return state;
  }
};

export default reducerVirtualMeeting;
